<template>
    <div class="gite">
        <div class="container-fluid center pt-3">
            <div class="row">
                <div class="col-2 align-self-center">
                  <router-link v-if="previous >= 0" :to="{ name: 'Gite', params: { id: previous} }" class="btn btn-info mt-3">&#x3008;</router-link>
                </div>
                <div class="col-8 align-self-center"><h1 class="h4 center">{{gite.nom}}</h1></div>
                <div class="col-2 align-self-center">
                  <router-link v-if="next < $store.state.gites.length" :to="{ name: 'Gite', params: { id: next} }" class="btn btn-info mt-3">&#x3009;</router-link>
                </div>
            </div>
        </div>
        <hr>
        <div class="container">
            <div class="row">
                <div class="col-sm-8 mb-3">
                  <p style="text-align: center">
                    <img :src="imageUrls[0]" class="img-fluid rounded-lg shadow"/>
                    <router-link :to="{ name: 'Reservation', params: { id: gite.id} }" class="btn btn-lg btn-success mt-3 mx-2">Réserver le gîte {{ gite.nom }}</router-link>
                  </p>
                </div>
                <div class="col-sm-4">
                    <p>
                        <b>Capacité</b><br>
                        {{ gite.capaciteAdulte }} adultes, {{ gite.capaciteEnfant }} enfant(s).
                    </p>
                    <p>
                        <b>Points forts</b><br>
                        {{ gite.info }} Salle de bain privée.
                    </p>
                    <p v-if="gite.escaliers">
                        Comporte un escalier.
                    </p>
                    <p v-if="gite.prixWeekEnd > 0 && gite.prixSemaine > 0 && gite.prixMois > 0">
                        <b>Prix</b><br>
                        {{ gite.prixWeekEnd }} € / week-end,<br>
                        {{ gite.prixSemaine }} € / semaine,<br>
                        {{ gite.prixMois }} € / mois.
                    </p>
                    <p v-else>
                        <b>Prix</b><br>
                        Sur devis.
                    </p>
                </div>
            </div>
        </div>
        <div class="container mb-3"> <!-- -->
            <h2 class="h4 my-3">Votre gîte en images.</h2>
            <hr>
            <div class="row shadow rounded">
                <div class="col-sm-6" v-for="(photos, i) in photoPagination" :key="i">
                    <img v-for="(photo, j) in photos" :key="j" :src="photo" class="img-fluid mb-1" style="width: 100%">
                </div>
            </div>
        </div>
        <div class="container center">
          <p style="text-align: center">
            <router-link :to="{ name: 'Reservation', params: { id: gite.id} }" class="btn btn-lg btn-success mt-3 mx-2">Réserver le gîte {{ gite.nom }}</router-link>
          </p>
        </div>
    </div>
</template>

<script>
export default {
  name: 'Gite',
  computed: {
    gite () {
      return this.$store.state.gites[this.$route.params.id]
    },
    next () {
      return parseInt(this.$route.params.id) + 1
    },
    previous () {
      return parseInt(this.$route.params.id) - 1
    },
    imageUrls () {
      const urls = []
      for (let i = 0; i < this.gite.imgs.length; i++) {
        urls.push('/imgs/' + this.gite.imgs[i])
      }
      return urls
    },
    photoPagination () {
      let temp = []
      const photosPaginees = []
      let compteur = 0
      for (let i = 0; i < this.imageUrls.length; i++) {
        temp.push(this.imageUrls[i])
        compteur += 1
        if (compteur >= this.imageUrls.length / 2) {
          compteur = 0
          photosPaginees.push(temp)
          temp = []
        }
      }
      photosPaginees.push(temp)
      return photosPaginees
    }
  }
}
</script>

<style scoped>
</style>
